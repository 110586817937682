/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import ReportService from "@/api/report-service";

const state = {
    reports: [],
    report: {},
    reportDetails: {},
};
const mutations = {
    storeReports(currentState: any, data: any): void {
        currentState.reports = data;
    },
    storeReport(currentState: any, data: any): void {
        currentState.report = { ...data };
    },
    removeReport(currentState: any, reportId: string): void {
        const reportIndex = currentState.reports?.findIndex(
            (rep: any) => rep.id === reportId,
        );

        currentState.reports.splice(reportIndex, 1);
    },
};
const actions = {
    async fetchReportList({ commit }: any): Promise<void> {
        const { data } = await ReportService.fetchReportList();
        console.log(data);
        commit("storeReports", data);
    },
    /**
     * Fetch Single Report
     * @param commit
     * @param id
     */
    async fetchReportById({ commit }: any, id: string): Promise<void> {
        const { data } = await ReportService.fetchReportById(id);
        commit("storeReport", data);
        return data;
    },
    // async fetchReportDetails({ commit }: any, payload: string): Promise<void> {
    //     const { data } = await ReportService.fetchReportDetails(payload);
    //     console.log("from the store", data);
    // },
    /**
     * Make Creator Action
     * @param _
     * @param payload
     */
    async makeCreator(_: any, payload: Array<string>): Promise<void> {
        const { data } = await ReportService.makeCreator(payload);
        // commit("storeUsers", data);
        return data;
    },
    /**
     * Make Reviewer Action
     * @param _
     * @param payload
     */
    async makeReviewer(_: any, payload: Array<string>): Promise<void> {
        const { data } = await ReportService.makeReviewer(payload);
        // commit("storeUsers", data);
        return data;
    },
    /**
     * Make Signatory Action
     * @param _
     * @param payload
     */
    async makeSignatory(_: any, payload: Array<string>): Promise<void> {
        const { data } = await ReportService.makeSignatory(payload);
        // commit("storeUsers", data);
        return data;
    },

    /**
     * Flag Report Principle Input
     * @param commit
     * @param id
     * @param body
     */
    async flagPrincipleInput(
        { commit }: any,
        { id, body }: { id: string; body: any },
    ): Promise<void> {
        const { data } = await ReportService.flagPrincipleInput({ id, body });
        return data;
    },

    /**
     * Flag Report Principle
     * @param commit
     * @param id
     * @param body
     */
    async flagPrinciple(
        { commit }: any,
        { id, body }: { id: string; body: any },
    ): Promise<void> {
        const { data } = await ReportService.flagPrinciple({ id, body });
        return data;
    },

    /**
     * Flag Report Standard
     * @param commit
     * @param id
     * @param body
     */
    async flagStandard(
        { commit }: any,
        { id, body }: { id: string; body: any },
    ): Promise<void> {
        const { data } = await ReportService.flagStandard({ id, body });
        return data;
    },

    /**
     * Flag Report Structure
     * @param commit
     * @param id
     * @param body
     */
    async flagPosition(
        { commit }: any,
        { id, body }: { id: string; body: any },
    ): Promise<void> {
        const { data } = await ReportService.flagPosition({ id, body });
        return data;
    },

    /**
     * Flag Report Document
     * @param commit
     * @param id
     * @param body
     */
    async flagDocument(
        { commit }: any,
        { id, body }: { id: string; body: any },
    ): Promise<void> {
        const { data } = await ReportService.flagDocument({ id, body });
        return data;
    },

    /**
     * Flag Report Signatory
     * @param commit
     * @param id
     * @param body
     */
    async flagSignatory(
        { commit }: any,
        { id, body }: { id: string; body: any },
    ): Promise<void> {
        const { data } = await ReportService.flagSignatory({ id, body });
        return data;
    },

    /**
     * Comment Principle Report
     * @param commit
     * @param id
     * @param body
     */
    async commentPrinciple(
        { commit }: any,
        { id, body }: { id: string; body: any },
    ): Promise<void> {
        const { data } = await ReportService.commentPrinciple({ id, body });
        return data;
    },
};

const getters = {
    getReport: ({ report }: any): any => report,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
