/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import UserService from "@/api/user-service";

const state = {
    users: [],
    activeUsers: [],
    levels: [],
    user: {},
};
const mutations = {
    storeUsers(currentState: any, data: any): void {
        currentState.users = data;
    },
    storeActiveUsers(currentState: any, data: any): void {
        currentState.activeUsers = data;
    },
    storeUser(currentState: any, data: any): void {
        currentState.user = { ...data };
    },
    storeLevels(currentState: any, data: any): void {
        currentState.levels = data;
    },
};
const actions = {
    /**
     * Create User
     * @param _
     * @param payload
     */
    async createUser(_: any, payload: any): Promise<void> {
        const { data } = await UserService.createUser(payload);
        return data;
    },

    /**
     * Fetch Single User
     * @param commit
     * @param userId
     */
    async fetchSingleUser({ commit }: any, userId: string): Promise<void> {
        const { data } = await UserService.fetchSingleUser(userId);
        commit("storeUser", data);
    },

    /**
     * Delete Single User
     * @param commit
     * @param userId
     */
    async deleteSingleUser({ commit }: any, userId: string): Promise<void> {
        const { data } = await UserService.deleteSingleUser(userId);
        // commit("storeUser", data);
        return data;
    },

    /**
     * Update Single User
     * @param commit
     * @param userId
     * @param body
     */
    async updateSingleUser(
        { commit }: any,
        { userId, body }: { userId: string; body: any },
    ): Promise<void> {
        const { data } = await UserService.updateSingleUser({ userId, body });
        commit("storeUser", data);
    },

    /**
     * Update Single User Roles
     * @param commit
     * @param userId
     * @param body
     */
    async updateSingleUserRoles(
        { commit }: any,
        { userId, body }: { userId: string; body: any },
    ): Promise<void> {
        const { data } = await UserService.updateSingleUserRoles({
            userId,
            body,
        });
        return data;
    },

    /**
     * Fetch Active Users
     * @param commit
     */
    async fetchActiveUsers({ commit }: any): Promise<void> {
        const { data } = await UserService.fetchActiveUsers();
        commit("storeActiveUsers", data);
    },
    async fetchUsers({ commit, dispatch }: any): Promise<void> {
        const { data } = await UserService.fetchUsers();
        const users = data.map((user: any) => {
            return {
                ...user,
                roles: user.position,
            };
        });
        await dispatch("fetchLevels");
        commit("storeUsers", users);
    },
    async fetchLevels({ commit }: any): Promise<void> {
        const { data } = await UserService.fetchLevels();
        commit("storeLevels", data);
    },
    async sendUserCredentials(_: any, id: string): Promise<void> {
        const { data } = await UserService.sendUserCredentials(id);
        console.log(data);
        // commit("storeUsers", data);
        return data;
    },
};

const getters = {
    users: ({ users }: { users: any }) => users,
    activeUsers: ({ activeUsers }: { activeUsers: any }) => activeUsers,
    user: ({ user }: { user: any }) => user,
    levels: ({ levels }: { levels: any }) => levels,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
